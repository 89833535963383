import BluetoothLogo from "../../../../docs/_images/bluetooth_logo.svg";
import SetupIde from "../../../../docs/_images/setup_ide.png";
import HelloWorld from "../../../../docs/_images/hello_world.png";
import Packages from "../../../../docs/_images/packages.svg";
import HwLed from "../../../../docs/_images/hw_led.jpg";
import HwLeds from "../../../../docs/_images/hw_leds.jpg";
import HwButton from "../../../../docs/_images/hw_button.jpg";
import PwmWiki from "../../../../docs/_images/pwm.png";
import Servo from "../../../../docs/_images/hw_servo.jpg";
import Buzzer from "../../../../docs/_images/hw_buzzer.jpg";
import Binary from "../../../../docs/_images/hw_binary.jpg";
import I2c from "../../../../docs/_images/i2c_logo.svg";
import Bme280 from "../../../../docs/_images/hw_bme280.jpg";
import Ssd1360 from "../../../../docs/_images/hw_ssd1306.jpg";
import Neopixel from "../../../../docs/_images/hw_neopixel.jpg";
import Adc from "../../../../docs/_images/adc_symbol.svg";
import Potentiometer from "../../../../docs/_images/hw_potentiometer.jpg";
import Dht11 from "../../../../docs/_images/hw_dht11.jpg";
import Ds18b20 from "../../../../docs/_images/hw_ds18b20.jpg";
import Ultra from "../../../../docs/_images/hw_ultra.jpg";
import Http from "../../../../docs/_images/http_logo.svg";
import Sleep from "../../../../docs/_images/sleep.svg";
import Date from "../../../../docs/_images/date.svg";
import Time from "../../../../docs/_images/time.svg";
import GoogleSheets from "../../../../docs/_images/google_sheets.png";
import Adafruit from "../../../../docs/_images/adafruit_io_logo.svg";
import Telegram from "../../../../docs/_images/telegram_logo.svg";
import Shushing from "../../../../docs/_images/shushing_face.svg";
import Supabase from "../../../../docs/_images/supabase_logo.svg";
import AwsIotCore from "../../../../docs/_images/aws_iot_core.png";
import Ota from "../../../../docs/_images/ota.png";
import CellTower from "../../../../docs/_images/cellular_tower.svg";
import Watchdog from "../../../../docs/_images/watchdog.svg";
import Qubitro from "../../../../docs/_images/qubitro_logo.svg";
import ToitLogo from "../../../../docs/_images/toit_logo.svg";
import CLogo from "../../../../docs/_images/c_logo.svg";
import * as React from 'react';
export default {
  BluetoothLogo,
  SetupIde,
  HelloWorld,
  Packages,
  HwLed,
  HwLeds,
  HwButton,
  PwmWiki,
  Servo,
  Buzzer,
  Binary,
  I2c,
  Bme280,
  Ssd1360,
  Neopixel,
  Adc,
  Potentiometer,
  Dht11,
  Ds18b20,
  Ultra,
  Http,
  Sleep,
  Date,
  Time,
  GoogleSheets,
  Adafruit,
  Telegram,
  Shushing,
  Supabase,
  AwsIotCore,
  Ota,
  CellTower,
  Watchdog,
  Qubitro,
  ToitLogo,
  CLogo,
  React
};