module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"be32ac1962ffe4b0394f7d71d887cdb5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"docs":"/home/runner/work/web-docs/web-docs/src/components/layout/MdxLayout.tsx","default":"/home/runner/work/web-docs/web-docs/src/components/layout/MdxLayout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"className":"table-of-contents-icon"}}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/web-docs/web-docs","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[],"usePrefix":["oembed","video"],"providers":{"include":["YouTube"],"exclude":["Reddit","Twitter","Flickr","Instagram"]}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
