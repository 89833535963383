// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-layout-mdx-layout-tsx": () => import("./../../../src/components/layout/MdxLayout.tsx" /* webpackChunkName: "component---src-components-layout-mdx-layout-tsx" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */)
}

