import VscodeTerminal from "../../../../docs/_images/setup_vscode_terminal.png";
import VscodeExtension from "../../../../docs/_images/setup_vscode_extension.png";
import VscodeToitExtension from "../../../../docs/_images/setup_vscode_toit_extension.png";
import JagPort from "../../../../docs/_images/setup_jag_port.png";
import JagWiFi from "../../../../docs/_images/setup_jag_wifi.png";
import JagFlash from "../../../../docs/_images/setup_jag_flash.png";
import JagMonitor from "../../../../docs/_images/setup_jag_monitor.png";
import JagScan from "../../../../docs/_images/setup_jag_scan.png";
import VscodeSplitTerminals from "../../../../docs/_images/setup_split_terminals.png";
import * as React from 'react';
export default {
  VscodeTerminal,
  VscodeExtension,
  VscodeToitExtension,
  JagPort,
  JagWiFi,
  JagFlash,
  JagMonitor,
  JagScan,
  VscodeSplitTerminals,
  React
};