import BinaryDiagram from "../../../../docs/_images/hw_ff_binary.png";
import BinarySchematics from "../../../../docs/_images/hw_ff_binary_schem.png";
import Vibration from "../../../../docs/_images/hw_vibration.jpg";
import Pir from "../../../../docs/_images/hw_pir.jpg";
import * as React from 'react';
export default {
  BinaryDiagram,
  BinarySchematics,
  Vibration,
  Pir,
  React
};